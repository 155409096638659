import React from 'react'
import * as styles from './NotFound.module.scss'
import SectionHeading from '../../common/Typography/SectionHeading'
import LinkButton from '../../common/Button/LinkButton'
import Container from '../../common/Container/Container'

export const NotFound = () => {
  return (
    <Container>
      <SectionHeading>Not found</SectionHeading>
      <div className={styles.heading}>
        Unfortunately the page you are looking for does not exist :(
      </div>

      <LinkButton variant="primary" to={'/'}>Go back home</LinkButton>
    </Container>
  )
}

export default NotFound
