import * as React from 'react'
import DefaultTemplate
  from '../components/common/Templates/DefaultTemplate/DefaultTemplate'
import NotFound from '../components/content/404/NotFound'
import { graphql } from 'gatsby'

const NotFoundPage = () => {
  return (
    <DefaultTemplate>
      <NotFound />
    </DefaultTemplate>
  )
}

export default NotFoundPage

export const query = graphql`
    {
        site {
            siteMetadata {
                title
                description
                keywords
            }
        }
    }
`

export const Head = ({ data }) => (
  <>
    <title>Not found :( | {data.site.siteMetadata.title}</title>
    <meta name="description" content={data.site.siteMetadata.description} />
    <meta name="keywords" content={data.site.siteMetadata.keywords} />
  </>
)
